import {take} from "./utils";

/*    */
const minHeight = 40;
const maxTouchElements = 3;
const touchContainerClass = 'nav_local-touch-container';
const categorySelector = '.nav_local-category';
const contentSelector = '.nav_local-content';

/*      */
const initialHeight = '0px';
const openDataKey = 'data-open-text';
const closedDataKey = 'data-closed-text';
const navLinkSelector = '.nav_local-link';
const hiddenContainerSelector = '.nav_local-hidden';
const hiddenContainerButtonSelector = '.nav_local-expand';

let touchBaseHeight = '';

function showHiddenContent(container, trackExpandCallback, evt) {
    let textKey = closedDataKey;
    let desiredHeight = initialHeight;

    if (container.style.height === initialHeight) {
        textKey = openDataKey;
        let links = container.querySelectorAll(navLinkSelector);
        let calculatedHeight = Array.from(links).reduce(
            (calculatedHeight, link) => calculatedHeight + link.offsetHeight,
            0);
        desiredHeight = calculatedHeight + 'px';
        trackExpandCallback('expand', evt.target);
    } else {
        trackExpandCallback('collapse', evt.target);
    }

    evt.target.innerHTML = evt.target.getAttribute(textKey);
    container.style.height = desiredHeight;
}


export function initDesktop(trackExpandCallback) {
    let buttons = window.document.querySelectorAll(hiddenContainerButtonSelector);
    if (!!buttons) {
        Array.prototype.forEach.call(buttons, button => {
            let hiddenContainer = button.parentElement.querySelector(hiddenContainerSelector);

            button.addEventListener('click', showHiddenContent.bind(this, hiddenContainer, trackExpandCallback), false);
            hiddenContainer.style.height = initialHeight;
        });
    }
}

/*                                                                   */
/*                                                                 */
/*                                                              */
/*                                                                    */
/*                               */
export function initTouch() {
    let touchContainer = window.document.getElementsByClassName(touchContainerClass)[0];

    if (!!touchContainer) {
        let localCategoryContainer = touchContainer.querySelectorAll(categorySelector);
        let elems = localCategoryContainer.length === 1 ?
            touchContainer.querySelectorAll(navLinkSelector) :
            localCategoryContainer;
        let height = take(maxTouchElements, elems).reduce((acc, it) => acc + Math.max(it.offsetHeight, minHeight), 0);
        touchBaseHeight = height + 'px';

        touchContainer.style.height = touchBaseHeight;

    }
    let contentContainer = window.document.querySelectorAll(contentSelector)[0];
    if (!!contentContainer) {
        contentContainer.addEventListener('click',
            e => {
                if (!!e.target.closest(navLinkSelector)) {
                    window.o_global.eventQBus.emit('ftfind.sheet.close');
                }
            });
    }
}

