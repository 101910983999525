/**
 *
 */
export function map(f, l) {
    return Array.prototype.map.call(l, f);
}

export function take(n, coll) {
    return Array.prototype.slice.call(coll, 0, n);
}

export function drop(n, coll) {
    return Array.prototype.slice.call(coll, n);
}

/**
 *
 */
export function kvMap(array) {
    let r = {};
    for (let k = array.shift(), v = array.shift();
         k != null;
         k = array.shift(), v = array.shift()) {
        if (!(v == null ||
            (Array.isArray(v) &&
                v.every(e => e == null)))) {
            r[k] = v;
        }
    }
    return r;
}

export function position(item, list){
    for (let j = 0; j < list.length; j++) {
        if (list[j] === item) {
            return j;
        }
    }
    return null;
}

export function mobilep() {
    let breakpoint = window.o_global.breakpoint.getCurrentBreakpoint();
    return (breakpoint === "s" || breakpoint === "m");
}
