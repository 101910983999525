import {mobilep} from "./utils";
import {trackExpanderFeaturesForDesktop} from "./featureTracking";
import {initViewTracking} from "./viewTracking";
import {initLinkTracking} from "./linkTracking";
import {initDesktop, initTouch} from "./localNavigation";

function init() {
    if (mobilep()) {
        initTouch();
    } else {
        initDesktop(trackExpanderFeaturesForDesktop);
    }

    initViewTracking();
    initLinkTracking();
}

function main() {
    window.o_global.eventLoader.onReady(5, init);
    window.o_global.eventQBus.on('ftfind.localNavigation.loaded', init);
}

main();