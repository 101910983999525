import {kvMap, mobilep, position} from "./utils";
import {send, types} from "./tracking";

const contextSelector = '.nav_local-content';
const listSelector = '.nav_local-category';
const itemSelector = 'li:not(.nav_link-headline)';
const headlineSelector = 'li.nav_link-headline';
const leafHeadlineSelector = 'p.nav_local-navigation-headline';
const contextName = 'LocalNavigation-Context';
const listName = 'LocalNavigation-List';
const itemName = 'LocalNavigation-Item';
const expanderButtonSelector = '.nav_local-expand';

const context_id = 'nav_local_context';

let pageContextEls =
    () => window.document.querySelectorAll(contextSelector);

let pageContextEl =
    () => pageContextEls()[0] || {};

let datasetStatus = (dataset) => (dataset.selected === 'true') ? 'selected' : 'visible';

const noop = () => {};

/*                                                                       */
const device = mobilep() ? 'mobile' : 'desktop';

const pagetype = (() => {
    let dataset = pageContextEl().dataset || {};
    return dataset.pagetype || 'unknown';
})();

const VARIANT = (() => {
    let dataset = pageContextEl().dataset || {};
    return dataset.variant || 'status-quo';
})();

/*        */

let contextFeatures = (contextEl, breakpoint) => {
    let dataset = contextEl.dataset || {};
    let labels = ['nav_LocalHierarchy', [dataset.navLevel], ('wk.nav_LocalContext' + dataset.suffix), [dataset.title]]
    if (dataset.navLocalCluster) {
        const clusterVal = breakpoint === "mobile" ?
            dataset.navLocalCluster + "_filter_sheet" : dataset.navLocalCluster
        labels.push('nav_LocalCluster', [clusterVal])
    }
    return [{
        'id': context_id,
        'name': contextName,
        'status': 'selected',
        'labels': kvMap(labels)
    }];
};

let listFeatures = (listEl, i, ...mods) => {
    let dataset = listEl.dataset || {};
    let status = datasetStatus(dataset);
    let titlekey = ('wk.nav_LocalList' + dataset.suffix);
    let features = [{'id': 'nav_local_list', /*                            */
        'parentId': context_id,
        'name': listName,
        'status': status,
        'position': (1 + i),
        'labels': kvMap(['nav_LocalType', [dataset.navType],
            'nav_LocalHierarchy', [dataset.navLevel],
            titlekey, [dataset.title]])}];
    return mods.reduce((fs, mod) => mod(fs, dataset),
        features);
};

let itemFeatures = (itemEl, i, j) => {
    let dataset = itemEl.dataset || {};
    return [{'id': 'nav_local_item', /*                            */
        'parentId': 'nav_local_list',
        'name': itemName,
        'status': 'clicked',
        'position': (1 + j),
        'labels': kvMap(['nav_LocalType', [dataset.navType],
            'nav_LocalHierarchy', [dataset.navLevel],
            ('wk.nav_LocalItem' + dataset.suffix), [dataset.title]])}];
};

let emptyItemFeatures = (suffix) => {
    return [{
        'id': 'nav_local_item',
        'parentId': 'nav_local_list',
        'status': 'deselected',
        'name': itemName,
        'labels': kvMap([
            ('wk.nav_LocalItem' + suffix), ["null"]
        ])
    }];
};

/*           */
function isSelected(el) {
    return el.dataset.selected !== "true";
}

/*         */

let withListInvisibleHeadline = (features, dataset) => {
    features.forEach((feature) => {
        feature['status'] = 'selected';
    });
    return features;
};

let withClick = (features, dataset) => {
    features.forEach((feature) => {
        feature['status'] = 'clicked';
    });
    return features;
};

/*    */

let sendMove = (features) => {
    send(types.moveAction, {}, {name: 'click', features: features});
};

/*              */

let trackHeadlineClicks = (event, contextEl) => {
    let el = event.target.closest('a');
    if (!!el) {
        let headlineEl = el.closest(headlineSelector);
        if (!!headlineEl) {
            let listEl = headlineEl.closest(listSelector);
            let listSiblings = contextEl.querySelectorAll(listSelector);
            let i = position(listEl, listSiblings);
            let ctx = contextFeatures(contextEl);
            let lst = listFeatures(listEl, i, withClick);
            let features = ctx.concat(lst);
            sendMove(features);
        }
    }
};

let trackLeafHeadlineClicks = (event, contextEl) => {
    let el = event.target.closest('a');
    if (!!el) {
        let headlineEl = el.closest(leafHeadlineSelector);
        if (!!headlineEl) {
            let ctx = contextFeatures(contextEl);
            let lst = listFeatures(headlineEl, 0, withClick);
            let suffix =  headlineEl.dataset.suffix || '';
            let itm = emptyItemFeatures(suffix);
            let features = ctx.concat(lst, itm);
            sendMove(features);
        }
    }
};

let trackItemClicks = (event, contextEl, opts) => {
    opts = opts || {};
    let listMods = opts.listMods || [];
    let el = event.target.closest('a');
    if (!!el) {
        let itemEl = el.closest(itemSelector);
        if (!!itemEl) {
            let listEl = itemEl.closest(listSelector);
            let itemSiblings = listEl.querySelectorAll(itemSelector);
            let j = position(itemEl, itemSiblings);
            let listSiblings = contextEl.querySelectorAll(listSelector);
            let i = position(listEl, listSiblings);
            let ctx = contextFeatures(contextEl, opts.breakpoint);
            let lst = listFeatures(listEl, i, ...listMods);
            let itm = itemFeatures(itemEl, i, j);
            let features = ctx.concat(lst, itm);
            sendMove(features);
        }
    }
};

let idempotentListen = (element, type, f) => {
    if (! (element.dataset.sulu_listens === 'true')) {
        element.addEventListener(type, f);
        element.dataset.sulu_listens = 'true';
    }
};

function trackLocalNavigationType(suffix, value) {
    let k = 'wk.nav_LocalNavigationType'+ suffix;
    let label = kvMap([k, value])
    send('tracking.bct.trackOnNextPageImpression', label);
}

/*               */
let initfns = {
    mobile: {
        products: () => {
            let init = () => {
                /*                                               */
                /*                       */
                for (let el of pageContextEls()) {
                    idempotentListen(el,
                        'click',
                        e => {
                            trackLocalNavigationType("ProductList", "mixed_filter_sheet_list");
                            trackItemClicks(e, el, {
                                listMods: [withListInvisibleHeadline],
                                breakpoint: "mobile"
                            })
                        });
                }
            };
            window.o_global.eventQBus.on('ftfind.filterSheet.openedNavigation', init);
        }
    },
    desktop: {
        module: () => {
            let contextEl = pageContextEl();
            contextEl.addEventListener('click',
                e => trackItemClicks(e, contextEl, {breakpoint: "desktop"}));
            contextEl.addEventListener('click',
                e => trackHeadlineClicks(e, contextEl));
            contextEl.addEventListener('click',
                e => {
                    let expanderButton = e.target.closest(expanderButtonSelector)
                    if (!expanderButton) {
                        trackLocalNavigationType("EntryPage", "static_mixed_list");
                    }
                });
        },
        entry: () => {
            let contextEl = pageContextEl();
            contextEl.addEventListener('click',
                e => trackItemClicks(e, contextEl, {
                    listMods: [],
                    breakpoint: "desktop"
                }));
            contextEl.addEventListener('click',
                e => trackLeafHeadlineClicks(e, contextEl));
            contextEl.addEventListener('click',
                e => trackLocalNavigationType("EntryPage", "static_mixed_list"));
        },
        products: () => {
            let contextEl = pageContextEl();
            contextEl.addEventListener('click',
                e => trackItemClicks(e, contextEl, {
                    listMods: [],
                    breakpoint: "desktop"
                }));
            contextEl.addEventListener('click',
                e => trackLeafHeadlineClicks(e, contextEl));
            contextEl.addEventListener('click',
                e => trackLocalNavigationType("ProductList", "static_mixed_list"));
        }
    }
};

let initfn = (device, pagetype) => {
    return ((initfns[device] || {})[pagetype] || noop);
};

/*  */

export function initLinkTracking() {
    initfn(device, pagetype)();
}
